import * as React from "react";
import { graphql } from "gatsby";
import { EventsPageContent } from "../../components/EventsPageContent";
import { Seo } from "../../components/Seo";

interface Props {
  data: any;
}

const ProgrammPage: React.FC<Props> = ({ data }) => {
  return (
    <EventsPageContent
      pageTitle="Unser Programm"
      allSanityEventCategories={data.allSanityEventCategories}
      allSanityEvents={data.allSanityEvents}
    />
  );
};

export const Head = () => (
  <Seo title="Programm" relativePageUrl={"/programm"} />
);

export default ProgrammPage;

export const query = graphql`
  query {
    allSanityEventCategories(sort: { fields: title, order: ASC }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    allSanityEvents(
      filter: { isPastDay: { eq: false } }
      sort: { fields: eventDate }
    ) {
      nodes {
        title
        slug {
          current
        }
        eventCategory {
          title
          slug {
            current
          }
          defaultTitleImage {
            asset {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                width: 200
                height: 200
              )
            }
          }
        }
        location
        titleImage {
          asset {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              width: 200
              height: 200
            )
          }
        }
        eventDate(formatString: "DD.MM.yyyy")
        eventEndDate(formatString: "DD.MM.yyyy")
      }
    }
  }
`;
